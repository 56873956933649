import React from "react";
import { SidebarPanel } from "@/components/organisms/SidebarPanel";
import { MenuItem } from "@/components/organisms/SidebarPanel/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ListIcon from "@mui/icons-material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import BadgeIcon from "@mui/icons-material/Badge";
import { routePaths } from "@/routes";
import { AppService } from "@/service/AllApiData.service";
import { useAuth } from "@/context/authContext";

const AdminSidebar = (props: any) => {

  const { permissions } = useAuth();

  return (
    <>
      <SidebarPanel isOpen={props.isOpen}>
        {
          (permissions?.length && permissions?.includes("dashboard.summary")) &&
          <MenuItem
            href={routePaths.dashboard}
            icon={<DashboardIcon />}
            label={"Dashboard"}
          />
        }

        {
          (permissions?.length && permissions?.includes("bazaar.list")) &&
          <MenuItem
            href={routePaths.admin.bazaars}
            icon={<ShoppingCartIcon />}
            label={"All Bazaars"}
          />
        }

        {/* <MenuItem
          href={routePaths.wholesellerproducts}
          icon={<ShoppingCartIcon />}
          label={"Products"}
        /> */}
        {
          (permissions?.length && permissions?.includes("wholeseller.list")) &&
          <MenuItem
            href={routePaths.wholesellerlist}
            icon={<ShoppingCartIcon />}
            label={"Wholesaler"}
          />
        }
        {
          (permissions?.length && permissions?.includes("plans.list")) &&
          <MenuItem
            href={"/plans"}
            icon={<CardMembershipIcon />}
            label={"Plans"}
          />
        }
        {
          (permissions?.length && permissions?.includes("agent.list")) &&
          <MenuItem href={"/agents"} icon={<BadgeIcon />} label={"Agents"} />
        }
        {
          (permissions?.length && permissions?.includes("ads.list")) &&
          <MenuItem href={"/advertisement"} icon={<ListIcon />} label={"Ads"} />
        }
        {/* <MenuItem href={"/mwb"} icon={<SettingsIcon />} label={"Routes"} /> */}
        {
          (permissions?.length && permissions?.includes("users.list") && permissions?.includes("roles_permissions.list")) &&
          <MenuItem
            href={"/rolepermission"}
            icon={<SettingsIcon />}
            label={"Roles & Permissions"}
          />
        }
        {
          (permissions?.length && permissions?.includes("activity_tracking.list")) &&
          <MenuItem
            href={"/trackorder"}
            icon={<SettingsIcon />}
            label={"Activity Tracking"}
          />
        }
        {
          (permissions?.length && permissions?.includes("master.list")) &&
          <MenuItem
            href={routePaths.admin.masterList}
            icon={<SettingsIcon />}
            label={"Master"}
          />
        }
        {
          (permissions?.length && permissions?.includes("reedem_model.list")) &&
          <MenuItem
            href={routePaths.agentCommissionRedeemModel}
            icon={<SettingsIcon />}
            label={"Agent Commission Redeem Model"}
          />
        }
      </SidebarPanel>
    </>
  );
};

export { AdminSidebar };
