import React, { useState, useEffect } from "react";
import { useNewWholesellerStyles } from "@/static/stylesheets/molecules";
import LogoPrev from "@/static/icons/ic_previous.png";
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Box,
  Grid,
  Dialog,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import { useNavigate, useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Alert, AlertError } from "@/alert/Alert";
import moment from "moment";
import { removeEmptyKeys } from "@/helper/util";
import { emailRegex } from "@/constant";
const AddNewWholeseller = () => {
  const navigate = useNavigate();
  const classes = useNewWholesellerStyles();
  const [masterType, setMasterType] = useState("Regional Wholesaler");
  const [stateList, setStateList] = useState<any[]>([]);
  const [stateList1, setStateList1] = useState<any[]>([]);
  const [bazaarList, setBazaarList] = useState<any>([]);
  const [states, setStates] = useState<any>();
  const [districtList, setDistrictList] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any>();
  const [bazaars, setBazaars] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [citys, setCitys] = useState<any>();
  const [wholesellerType, setWholesellerType] = useState<any[]>([]);
  const [WholesellerAgent, setWholesellerAgent] = useState<any[]>([]);
  const [Wholesellerretailer, setWholesellerretailer] = useState<any[]>([]);
  const [wholesellerModalOpen, setwholesellerModalOpen] = useState(false);
  const [AllBazaar, setAllBazaar] = useState<any>([]);
  const [inputPhone, setinputPhone] = useState<any>("");
  const [wholesellerData, setWholesellerData] = useState<any>({});
  const { id } = useParams<any>()
  const [wholesellerdata, setwholesellerdata] = useState<any>({
    bazaar: "",
    wholeseller_type_name: "",
  });
  const [count, setconut] = useState<any>(1);
  const [initialValues, setinitialValues] = useState({
    wholeseller_description: "",
    wholeseller_name: "",
    wholeseller_type: "",
    wholeseller_retailer_type: "",
    wholeseller_firm_name: "",
    wholeseller_contact_per: "",
    wholeseller_number: "",
    wholeseller_altranate_number: "",
    wholeseller_email_id: null,
    wholeseller_adhar_no: null,
    wholeseller_gst_no: null,
    wholeseller_firm_pan_no: null,
    wholeseller_address: null,
    wholeseller_landmark: null,
    wholeseller_pincode_no: null,
    wholeseller_adhar_front_image: "",
    wholeseller_adhar_back_image: "",
    wholeseller_pan_card_image: "",
    wholeseller_image: "",
    wholeseller_status: "CREATED",
    wholeseller_active: true,
    get_wholeseller_location_json_data: null,
    created_at: moment().format("YYYY-MM-DD"),
    wholeseller_plan: [],
    wholeseller_payment: null,
    wholeseller_agent: null,
    wholeseller_city: null,
    wholeseller_state: null,
    wholeseller_district: null,
    wholeseller_bazaar: null,
  });

  console.log(states, districtList, "inputPhone")
  useEffect(() => {
    getTotalStates();
    getWholesellerType();
    getWholesellerAgent();
    getWholesellerretailer({ ids: initialValues?.wholeseller_city });
    deteliswholseller()
  }, []);

  useEffect(() => {
    if (count) {
      getAllBazaar();
    }
  }, [count]);

  useEffect(() => {
    getDistrictByStates();
  }, [states]);

  useEffect(() => {
    getAllCity();
  }, [districts]);

  const deteliswholseller = async () => {
    if (id) {
      const responseJson = await AppService.getWholesellerById(id)
      setinitialValues({
        ...responseJson.data,
        wholeseller_firm_name: responseJson.data.wholeseller_firm_name,
        wholeseller_contact_per: responseJson.data.wholeseller_contact_per,
        wholeseller_email_id: responseJson.data.wholeseller_email_id,
        wholeseller_type: responseJson.data.wholeseller_type.id,
        wholeseller_state: responseJson.data.wholeseller_state.id,
        wholeseller_district: responseJson.data.wholeseller_district.id,
        wholeseller_city: responseJson.data.wholeseller_city.id,
        wholeseller_bazaar: responseJson.data.wholeseller_bazaar[0],
        wholeseller_number: responseJson.data.wholeseller_number.replace("+91", "")
      })
      setWholesellerData({
        // ...responseJson.data,
        wholeseller_firm_name: responseJson.data.wholeseller_firm_name,
        wholeseller_contact_per: responseJson.data.wholeseller_contact_per,
        wholeseller_email_id: responseJson.data.wholeseller_email_id,
        wholeseller_type: responseJson.data.wholeseller_type.id,
        wholeseller_state: responseJson.data.wholeseller_state.id,
        wholeseller_district: responseJson.data.wholeseller_district.id,
        wholeseller_city: responseJson.data.wholeseller_city.id,
        wholeseller_bazaar: responseJson.data.wholeseller_bazaar[0],
        wholeseller_number: responseJson.data.wholeseller_number.replace("+91", "")
      })
      const responseJson1 = await AppService.getAllDistricByState({
        ids: responseJson.data.wholeseller_state.id,
      });
      setDistrictList(responseJson1.data.results);
      const responseJson2 = await AppService.getAllCityByDis({
        ids: responseJson.data.wholeseller_district.id,
      });
      setCityList(responseJson2.data.results);
      setinputPhone(responseJson.data.wholeseller_number.replace("+91", ""))
    }
  }

  const handleChangeMasterType = (event: SelectChangeEvent) => {
    setMasterType(event.target.value as string);
  };

  const getTotalStates = async () => {
    const responseJson = await AppService.getTotalStates();
    setStateList(responseJson.data);
  };

  const getDistrictByStates = async () => {
    const responseJson = await AppService.getAllDistricByState({
      ids: states.toString(),
    });
    console.log(responseJson.data.results);
    setDistrictList(responseJson.data.results);
    // console.log("logger123...",bazaars,states,AllBazaar,AllBazaar?.find((ele:any)=>ele.id === bazaars));
    // const districts = AllBazaar?.find((ele:any)=>ele.id === bazaars)?.bazaar_district_names?.filter((ele:any)=>ele.state === states)
    // console.log("logger123...",bazaars,states,districts);
    // setDistrictList(districts || []);
  };

  const getAllCity = async () => {
    const responseJson = await AppService.getAllCityByDis({
      ids: districts.toString(),
    });
    console.log("districts", responseJson.data.results);
    setCityList(responseJson.data.results);

    // const cities = AllBazaar?.find((ele:any)=>ele.id === bazaars)?.bazaar_city_names?.filter((ele:any)=>ele.state === states && ele.district === districts)
    // console.log("logger123...",bazaars,states,cities);
    // setCityList(cities || []);
  };

  const getWholesellerType = async () => {
    const responseJson = await AppService.getWholesellerType({});
    console.log("responseJson.data.results", responseJson.data.results);
    setWholesellerType(responseJson.data.results);
  };
  const getWholesellerAgent = async () => {
    const responseJson = await AppService.getAllAgents();
    setWholesellerAgent(responseJson.data.results);
  };
  const getWholesellerretailer = async (param: any) => {
    const responseJson = await AppService.getRetailerType(param);
    setWholesellerretailer(responseJson.data.results);
  };

  const wholesellerTypeSchema = Yup.object().shape({
    wholeseller_type_name: Yup.string().trim().required("Wholeseller type name is required"),
    bazaar: Yup.string().trim().required("Bazaar is required")
  })

  const SignupSchema = Yup.object().shape({
    wholeseller_firm_name: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Firm name is required"),
    wholeseller_contact_per: Yup.string()
      .trim()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Contact person is required"),
    wholeseller_number: Yup.string()
      .matches(/^[1-9]\d{9}$/, 'Phone number is invalid')
      .required('Phone number is required')
      .test('unique-mobile-number', 'Mobile Number already registered with mwb', async function (value) {
        if (id && value === wholesellerData.wholeseller_number) {
          return true;
        }
        const isDuplicate = await AppService.isWholesellerNumberExists(value);
        return !isDuplicate;
      }),
    wholeseller_bazaar: Yup.string().required("Bazaar is required"),
    wholeseller_state: Yup.string().required("State is required"),
    wholeseller_district: Yup.string().required("District is required"),
    wholeseller_city: Yup.string().required("City is required"),
    wholeseller_type: Yup.string().required("Wholeseller type is required"),
    wholeseller_email_id: Yup.string().required("Email is required").matches(emailRegex, "Email is invalid")
      .test('unique-email', 'Email already registered with mwb', async function (value) {
        if (id && value === wholesellerData.wholeseller_email_id) {
          return true;
        }
        const isDuplicate = await AppService.isWholesellerEmailExists(value);
        return !isDuplicate;
      })
  });

  const handleFormSubmit = async (values: any) => {
    let formdata = { ...initialValues, ...values };
    console.log("values", values);
    formdata.wholeseller_number = formdata.wholeseller_number ? "+91" + formdata.wholeseller_number : null;
    // formdata.wholeseller_altranate_number = formdata.wholeseller_altranate_number ? "+91" + formdata.wholeseller_altranate_number : null;
    formdata.wholeseller_bazaar = [Number(values?.wholeseller_bazaar)]
    try {
      let responseJson;

      if (id) {
        formdata = {
          wholeseller_firm_name: values.wholeseller_firm_name,
          wholeseller_contact_per: values.wholeseller_contact_per,
          wholeseller_email_id: values.wholeseller_email_id,
          wholeseller_type: values.wholeseller_type.id,
          wholeseller_state: values.wholeseller_state.id,
          wholeseller_district: values.wholeseller_district.id,
          wholeseller_city: values.wholeseller_city.id,
          wholeseller_bazaar: [Number(values?.wholeseller_bazaar)],
          wholeseller_number: values.wholeseller_number ? "+91" + values.wholeseller_number : null
        }
        responseJson = await AppService.updateWholeseller(id, formdata);
      } else {
        responseJson = await AppService.addWholeseller(formdata);
      }

      if (responseJson.status == 201) {
        Alert("WholeSeller added successfully");
        navigate("/wholesellerlist");
      } else if (responseJson.status == 200) {
        Alert("WholeSeller edit successfully");
        navigate("/wholesellerlist");
      }
    } catch (error: any) {
      let message = error.response.data.type + "\n";
      error.response.data.errors.map((row: any) => {
        message += row.attr + " : " + row.detail + "\n";
      });
      AlertError(message);
    }
  };
  console.log(initialValues, "initialValuesinitialValues")

  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();
    if (responseJson.status == 200) {
      // setconut(count + 1);
      setAllBazaar(responseJson.data);
    }
    let tempBazaar = await responseJson.data.map((row: any) => {
      return {
        label: row.bazaar_name,
        value: row.id,
        ...row
      };
    });
    setBazaarList(tempBazaar);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setwholesellerdata((prev: any) => ({ ...prev, [name]: value }));
  };

  const AddWholeseller = async (values: any) => {
    if (
      values?.bazaar != "" &&
      values?.wholeseller_type_name != ""
    ) {
      const response = await AppService.addWholesellerType(values);
      if (response.status == 201) {
        getWholesellerType();
        setwholesellerModalOpen(false);
        Alert(`wholeseller added successfully`);
      }
    } else {
      setwholesellerModalOpen(false);
      AlertError(`please fill in all fields`);
    }
  };

  useEffect(() => {
    // if (bazaars) {
    //   bazaargetdata(bazaars)
    // }
    getAllStates();
  }, [])

  console.log(bazaars, bazaarList, "initialValues?.wholeseller_bazaar")

  const getAllStates = async () => {
    const responseJson = await AppService.getAllStates()
    setStateList1(responseJson.data?.results)
  }


  const handleStateChange = (list: any, e: any, setState: any, city = false) => {

    console.log("list.....123...", e)

    if (e.length > 1) {
      if (e.length > list.length) {
        if (city) {
          let body = { target: { name: "wholeseller_city", value: [...e] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_district", value: [...e] } }
          setState(body)
          setDistricts([...e])
        }
      } else {
        if (city) {
          let body = { target: { name: "wholeseller_city", value: [] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_district", value: [] } }
          setState(body)
          setDistricts([...e])
        }
      }
    } else {
      if (list.includes(e[0])) {
        if (city) {
          let body = { target: { name: "wholeseller_city", value: [...list.filter((item: any) => item !== e[0])] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_district", value: [...list.filter((item: any) => item !== e[0])] } }
          setState(body)
          setDistricts([...list.filter((item: any) => item !== e[0])])
        }
      } else {

        if (city) {
          let body = { target: { name: "wholeseller_city", value: [...list, e[0]] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_district", value: [...list, e[0]] } }
          setState(body)
          setDistricts([...list, e[0]])
        }

      }
    }
    console.log("logger123..", list)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={SignupSchema}
        enableReinitialize={true}
        className="dgdfh"
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form>
            <>{console.log("wholeseller_bazaar", errors)}</>
            <div className={classes.root}>
              <div className="headContainer">
                <div className="icon" onClick={() => navigate(-1)}>
                  <img src={LogoPrev} alt={"Logo"} />
                </div>
                <div className="headTitle">{id ? "Edit Wholesaler" : "Add New Wholesaler"}</div>
              </div>

              <div className="field">
                <Grid container spacing={2} style={{ alignItems: "end" }}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <p className="fieldTitle">Firm Name <span className=" text-red-600">*</span></p>
                      <TextField
                        variant="standard"
                        id="fullWidth"
                        name="wholeseller_firm_name"
                        value={values.wholeseller_firm_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full"
                        style={{ width: "100%" }}
                      />

                      {errors.wholeseller_firm_name && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_firm_name}</div>}

                    </div>
                  </Grid>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <p className="fieldTitle">Email <span className=" text-red-600">*</span></p>
                      <TextField
                        variant="standard"
                        id="fullWidth"
                        name="wholeseller_email_id"
                        value={values.wholeseller_email_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full"
                        style={{ width: "100%" }}
                      />

                      {errors.wholeseller_email_id && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_email_id}</div>}

                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}></Grid>
                <Grid container spacing={2} style={{ alignItems: "center" }}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <div className="w-full">
                        <p className="fieldTitle">Contact Person <span className=" text-red-600">*</span></p>
                        <TextField
                          name="wholeseller_contact_per"
                          value={values.wholeseller_contact_per}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="standard"
                          id="fullWidth"
                          className="w-full"
                          style={{ width: "100%" }}
                        />

                        {errors.wholeseller_contact_per && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_contact_per}</div>}

                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="fieldTitle">Phone Number <span className=" text-red-600">*</span></p>
                        <Input
                          id="standard-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">
                              +91{" "}
                            </InputAdornment>
                          }
                          name="wholeseller_number"
                          type="text"
                          value={inputPhone}
                          onBlur={handleBlur}
                          onChange={(e: any) => {
                            handleChange(e); setinputPhone(e.target.value)
                          }}
                          inputProps={{ maxLength: 10 }}
                          onWheel={(e) => e.preventDefault()}
                        />

                        {errors.wholeseller_number && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_number}</div>}

                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ alignItems: "center" }}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <InputLabel id="Customer-Type">Customer Type <span className=" text-red-600">*</span></InputLabel>
                      <Select
                        labelId="Customer-Type"
                        label="Customer Type"
                        variant={"standard"}
                        fullWidth={true}
                        value={values.wholeseller_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="wholeseller_type"
                      >
                        {wholesellerType.map((row) => (
                          <MenuItem value={row.id}>
                            {row.wholeseller_type_name}
                          </MenuItem>
                        ))}
                      </Select>

                      {errors.wholeseller_type && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_type}</div>}


                      {/* <Box sx={{ color: "red" }}>
                        <ErrorMessage name="wholeseller_type" />
                      </Box> */}
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <a
                        href=""
                        onClick={(e) => (
                          setwholesellerModalOpen(true), e.preventDefault()
                        )}
                        className="text-purple text-[15px] leading-5 font-semibold"
                      >
                        Create New Type
                      </a>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ alignItems: "center" }}>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <InputLabel id="Select-State">State <span className=" text-red-600">*</span></InputLabel>
                      <Select
                        labelId="Select-State"
                        label="Select State"
                        variant={"standard"}
                        fullWidth={true}
                        value={values.wholeseller_state}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setStates(e.target.value);
                          setFieldValue("wholeseller_district", null)
                          setDistricts(null);
                          setCitys(null)
                        }}
                        name="wholeseller_state"
                      >
                        {stateList1?.map((row) => (
                          <MenuItem value={row?.id}>{row?.state}</MenuItem>
                        ))}
                      </Select>

                      {errors.wholeseller_state && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_state}</div>}

                      {/* <Box sx={{ color: "red" }}>
                        <ErrorMessage name="wholeseller_state" />
                      </Box> */}
                    </div>
                  </Grid>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <InputLabel id="Select-Districts">Districts <span className=" text-red-600">*</span></InputLabel>

                      {/* <div className="Selectdistrict">
                        <DistrictPicker
                          label={"Select District"}
                          // placeholder={"Select District"}
                          hint={"Search"}
                          variant={"outlined"}
                          states={stateList1}
                          selectedStates={[values.wholeseller_state]}
                          districts={districtList}
                          selectedDistricts={values.wholeseller_district}
                          onSelectItem={(e: any) => {
                            handleStateChange(values.wholeseller_district, e, handleChange)
                            handleChange({ target: { name: "wholeseller_city", value: [] } })
                          }}
                        />

                      </div> */}

                      <Select
                        labelId="Select-Districts"
                        label="Select Districts"
                        variant={"standard"}
                        fullWidth={true}
                        value={values.wholeseller_district}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setDistricts(e.target.value);
                          setFieldValue("wholeseller_city", null)
                          setCitys(null)
                        }}
                        name="wholeseller_district"
                      >
                        {districtList.map((row) =>
                          row.district.map((innerrow: any) => (
                            <MenuItem value={innerrow.id}>
                              {innerrow.district}
                            </MenuItem>
                          ))
                        )}
                      </Select>

                      {errors.wholeseller_district && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_district}</div>}

                      {/* <Box sx={{ color: "red" }}>
                        <ErrorMessage name="wholeseller_district" />
                      </Box> */}
                    </div>
                  </Grid>

                </Grid>
                <Grid container spacing={2} style={{ alignItems: "center" }}>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <InputLabel id="Select-Districts">City <span className=" text-red-600">*</span></InputLabel>

                      {/* <CityPickerNew label={"Assigned Cities"}
                                  // placeholder={"Select Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={districtList}
                                  selectedStates={values.wholeseller_district}
                                  selectedDistricts={values.wholeseller_district}
                                  districts={cityList}
                                  selectedCity={values.wholeseller_city}
                                  selectedcity={values.wholeseller_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.wholeseller_city,id,handleChange,true)} 
                                  /> */}

                      <Select
                        labelId="Select-City"
                        label="Select City"
                        variant={"standard"}
                        fullWidth={true}
                        value={values.wholeseller_city}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setCitys(e.target.value);
                        }}
                        name="wholeseller_city"
                      >
                        {cityList.map((row) =>
                          row.city.map((innerrow: any) => (
                            <MenuItem value={innerrow.id}>
                              {innerrow.city}
                            </MenuItem>
                          ))
                        )}
                      </Select>

                      {errors.wholeseller_city && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_city}</div>}
                      {/* <Box sx={{ color: "red" }}>
                        <ErrorMessage name="wholeseller_city" />
                      </Box> */}
                    </div>
                  </Grid>

                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px", height: "101px" }}>
                      <InputLabel id="Select-Bazaar">Bazaar <span className=" text-red-600">*</span></InputLabel>
                      <Select
                        labelId="Select-Bazaar"
                        label="Select Bazaar"
                        variant={"standard"}
                        fullWidth={true}
                        value={values.wholeseller_bazaar}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e)
                          // setFieldValue("wholeseller_state",null)
                          // setFieldValue("wholeseller_district",null)
                          // setFieldValue("wholeseller_city",null)
                          // setStates(null);
                          // setDistricts(null);
                          // setCitys(null)
                        }}
                        name="wholeseller_bazaar"
                      >
                        {bazaarList.map((row: any) => (
                          <MenuItem onClick={() => setBazaars(row.value)} value={row.value}>{row.label}</MenuItem>
                        ))}
                      </Select>

                      {errors.wholeseller_bazaar && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_bazaar}</div>}
                      {/* <Box sx={{ color: "red" }}>
                        <ErrorMessage name="wholeseller_bazaar" />
                      </Box> */}
                    </div>
                  </Grid>

                </Grid>
              </div>
              <div className="actionButton pl-[30px] !pt-[45px]">
                <ActionButton variant="default" title="cancel" type="button" onClick={() => navigate('/wholesellerlist')} />
                <ActionButton type="submit" variant="primary" title="save" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Dialog
        open={wholesellerModalOpen}
        maxWidth={"lg"}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
        onClose={() => setwholesellerModalOpen(false)}
      >
        <Formik
          initialValues={{
            wholeseller_type_name: "",
            bazaar: ""
          }}
          validationSchema={wholesellerTypeSchema}
          onSubmit={AddWholeseller}
        >
          {
            ({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              errors,
              handleSubmit
            }) => (
              <div className="p-[44px] w-[461px]">

                <div
                  className={
                    "title font-[600] text-[24px] leading-[20px] text-[#2e2c34] mb-[27px]"
                  }
                >
                  Add Wholesaler Type
                </div>

                <div className={"select-master pt-[26px]"}>
                  <div
                    className={
                      "input-label font-[500] text-[12px] leading-[18px] text-[#84818a]"
                    }
                  >
                    Wholesaler Type
                  </div>
                  <TextField
                    variant="standard"
                    fullWidth={true}
                    name={"wholeseller_type_name"}
                    onChange={handleChange}
                  />
                  {errors.wholeseller_type_name && <div className="text-red-600 text-xs mt-2">{errors.wholeseller_type_name}</div>}

                  <div className="select-master pt-[26px]">
                    <p className="input-label font-[500] text-[12px] leading-[18px] text-[#84818a]">
                      Bazaar
                    </p>
                    <Select
                      label="Age"
                      variant={"standard"}
                      fullWidth={true}
                      onChange={handleChange}
                      name="bazaar"
                    >
                      {AllBazaar?.map((items: any) => (
                        <MenuItem key={items.id} value={items.id}>
                          <ListItemText primary={items.bazaar_name} />
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.bazaar && <div className="text-red-600 text-xs mt-2">{errors.bazaar}</div>}
                  </div>
                </div>

                <div className={"action-bar flex gap-[12px] mt-[41px]"}>
                  <ActionButton
                    variant={"default"}
                    title={"Cancel"}
                    onClick={() => setwholesellerModalOpen(false)}
                  />

                  <ActionButton
                    variant={"primary"}
                    title={"Add"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )
          }
        </Formik>

      </Dialog>
    </>
  );
};

export { AddNewWholeseller };
