import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { RoleSection } from "@/components/molecules/rulespermission/roleSection";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dots from "@/static/icons/dots-1.svg";
import setting from "@/static/svg/setting.svg";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AppService } from "@/service/AllApiData.service";
import { GridOptionButton } from "@/components/atoms/Button";
import {BiEdit} from "react-icons/bi"
import moment from "moment";

const RolesPermission = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [rolesData, setRolesData] = useState<any>([]);
  const [count, setCount] = useState(10);
  const [currentPage,setCurrentPage] = useState(1);
  const [users,setUsers] = useState<any>([]);

  useEffect(() => {
    let params = { page: currentPage }
    getRoles(params);
  }, [currentPage]);

  useEffect(()=>{
    getUsers();
  },[])

  const getRoles = async (params:any) => {
    const res = await AppService.getRoles(params);
    let roles = res.data.roles.map((role:any)=>(
      {
        ...role,
        members: users.filter((item:any)=>item?.roles?.id === role?.id).map((item:any)=>item?.first_name)
      }
    ))
    console.log(roles)
    setRolesData(roles);
    setCount(res.data.total_count)
  };

  const getUsers = async () => {
    const res = await AppService.getUsers({});
    setUsers(res.data)
    let params = { page: currentPage }
    getRoles(params);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = async (e: any, p: any) => {
    setCurrentPage(p);
  };

  return (
    <DashboardLayout sectionName="roles_premission_section">
      <Box className="w-full px-[49px] mb-[50px] min-h-[calc(100vh_-_195px)]">
        <RoleSection />
        <div className="mt-[35px]">
          <table className="w-full text-left">
            <thead>
              <tr>
              <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] pb-[11px]">
                  Sr No.
                </th>
              <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] pb-[11px]">
                  Date
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] pb-[11px]">
                  Name
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] pb-[11px]">
                  Members
                </th>
                <th className="text-[#84818A] text-[14px] font-[600] font-[Manrope] pb-[11px]">
                  Permissions
                </th>
              </tr>
            </thead>
            <tbody>
              {rolesData.map((data: any,index:number) => (
                <tr className="border-b border-[#EBEAED]">
                  <td className="py-[17px]">
                    <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                    {index + 1 + ((currentPage - 1) * 10)}
                    </p>
                  </td>
                  <td className="py-[17px]">
                    <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                      {moment(data?.created).format("DD-MM-YYYY HH:MM:SS A")}
                    </p>
                  </td>
                  <td className="py-[17px]">
                    <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                      {data.name}
                    </p>
                  </td>
                  <td className="py-[17px]">
                  <Tooltip title={data?.members?.map((item:any)=>item)?.join(", ")}>
                   <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                    {data?.members?.length-2 > 0 ? `${data?.members[0]}, ${data?.members[0]}, + ${data?.members?.length-2}` : data?.members?.map((item:any)=>item)?.join(", ") }
                   </p>
                    </Tooltip>
                  </td>
                  <td className="py-[17px]">
                    <Tooltip title={data?.permissions?.map((item:any)=>item)?.join(", ")}>
                   <p className="text-[#2E2C34] text-[14px] font-[500] font-[Manrope]">
                    {data?.permissions.length-2 > 0 ? `${data?.permissions[0]}, ${data?.permissions[0]}, + ${data?.permissions.length-2}` : data?.permissions?.map((item:any)=>item)?.join(", ") }
                   </p>
                    </Tooltip>
                  </td>

                  <td className="py-4 px-6 tableData cursor-pointer" style={{ justifyContent: "end", display: "flex", paddingRight: 0 }}>
                          {
                            <GridOptionButton
                              icon={"vertical-options"}
                              menus={[
                                {
                                  label: (
                                    <>
                                      <span className="icon">
                                        {/* <img src={complete} alt="dots" /> */}
                                        <BiEdit />
                                      </span>
                                      <p onClick={() => {navigate("/roles/"+data?.id+"/edit")}}>
                                        Edit Role
                                      </p>

                                    </>
                                  ),
                                }
                              ]}
                            />
                          }

                        </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <Dialog
            open={open}
            PaperProps={{
              style: {
                borderRadius: 20,
                boxShadow: "none",
                padding: "20px 45px",
              },
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="addRole"
          >
            <DialogTitle id="alert-dialog-title">
              <span className="text-[#2E2C34] text-[24px] font-[Manrope] leading-5 font-semibold">
                Zonal Manager -
              </span>{" "}
              <span className="text-[#84818A] font-normal text-[24px] font-[Manrope] leading-5 ">
                Modify Permissions
              </span>
            </DialogTitle>
            <DialogContent className="!pt-[10px]">
              <Typography className="title !font-[Manrope]">
                Permissions Group Title
              </Typography>
              <Typography className="para !font-[Manrope]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        color: "#84818A",
                        "&.Mui-checked": {
                          color: "#4e2fa9",
                        },
                      }}
                    />
                  }
                  label={
                    <p className="!font-[Manrope] font-[600] text-[14px] text-[#2E2C34]">
                      Permission Name
                    </p>
                  }
                  className="checkbox-addrole"
                />
                <span className="pera-text !font-[Manrope]">
                  A short description of the permissions will be displayed here
                </span>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#84818A",
                        "&.Mui-checked": {
                          color: "#4e2fa9",
                        },
                      }}
                    />
                  }
                  label={
                    <p className="!font-[Manrope] font-[600] text-[14px] text-[#2E2C34]">
                      Permission Name
                    </p>
                  }
                  className="checkbox-addrole"
                />
                <span className="pera-text !font-[Manrope]">
                  A short description of the permissions will be displayed here
                </span>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        color: "#84818A",
                        "&.Mui-checked": {
                          color: "#4e2fa9",
                        },
                      }}
                    />
                  }
                  label={
                    <p className="!font-[Manrope] font-[600] text-[14px] text-[#2E2C34]">
                      Permission Name
                    </p>
                  }
                  className="checkbox-addrole"
                />
                <span className="pera-text !font-[Manrope]">
                  A short description of the permissions will be displayed here
                </span>
              </FormGroup>
              <Typography className="title pt-8 !font-[Manrope] !font-[600]">
                Permissions Group Title
              </Typography>
              <Typography className="para !font-[Manrope]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{
                        color: "#84818A",
                        "&.Mui-checked": {
                          color: "#4e2fa9",
                        },
                      }}
                    />
                  }
                  label={
                    <p className="!font-[Manrope] font-[600] text-[14px] text-[#2E2C34]">
                      Permission Name
                    </p>
                  }
                  className="checkbox-addrole"
                />
                <span className="pera-text !font-[Manrope]">
                  A short description of the permissions will be displayed here
                </span>
              </FormGroup>
            </DialogContent>
            <Box className="divider"></Box>
            <DialogActions>
              <ActionButton
                onClick={handleClose}
                variant="default"
                title="Cancel"
              />
              <ActionButton
                onClick={handleClose}
                variant="primary"
                title="Save Changes"
              />
            </DialogActions>
          </Dialog>
        </div>
      </Box>
      <div
        className="w-full h-[60px] bg-white"
        style={{ boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="flex justify-between items-center w-full h-full px-[39px]">
          <p className="text-[#84818A] font-[Manrope] text-[12px] font-[600]">
            Show {rolesData.length} from {count} products
          </p>
          <div>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#84818A",
                    fontFamily: "Manrope",
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "rgba(85, 66, 246, 0.1) !important",
                    color: "rgba(85, 66, 246, 1)",
                  },
                }}
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default RolesPermission;
