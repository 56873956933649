import React, { useEffect, useState } from "react";
import { useBazaarListStyles } from "@/static/stylesheets/molecules/";
import LogoEcBazaar from "@/static/icons/ic_bookmark.png";
import LogoDot from "@/static/icons/ic_dot.png";
import { AppService } from "@/service/AllApiData.service";
import { Pagination } from "@mui/lab";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";
import { GridOptionButton } from "@/components/atoms/Button";
import fill from "@/static/icons/fill.svg";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/routes";


const BazaarsListView = (props: any) => {
  const classes = useBazaarListStyles();
  const navigate = useNavigate();
  const [getAllBazar, setGetAllBazar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, SetTotalCount] = useState(10);

  const ITEMS_PER_PAGE = 10;
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
    // getAllLists(value);
    console.log(value);
  };
  const search = props.searchItem
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = getAllBazar;

  const getAllLists = async () => {
    let params = {
      page: search ? 1 : currentPage,
      search: search
    }

    const responseJson = await AppService.getAllBazarList(params);
    setGetAllBazar(responseJson.data.results);
    SetTotalCount(responseJson.data.count)
  };

  const GetALLDATA = getAllBazar?.filter((elm: any) => elm?.bazaar_name?.toLowerCase().includes(search)).length > 0 ? getAllBazar?.filter((elm: any) => elm?.bazaar_name?.toLowerCase().includes(search)) : getAllBazar;

  useEffect(() => {
    setTimeout(getAllLists, 600)
  }, [currentPage, search]);

  return (
    <div className={classes.root}>
      <table>
        <tr>
          <th className="nameTitle">BAZAAR NAME</th>
          {/* <th className="categoryTitle">GROUP CATEGORY</th> */}
          <th className="wholesellerTitle">WHOLESALERS</th>
          <th className="agentTitle">AGENTS</th>
          <th className="statesTitle">STATES</th>
          <th className="revenewEarned">REVENUE EARNED</th>
          <th className="billsTitle">NO. OF BILLS</th>
          <th></th>
        </tr>
        {GetALLDATA.map((item: any) => (
          <tr>
            <td>
              <div className="brandData">
                <img className="brandLogo" src={item.bazaar_image} alt={"Logo"} />
                {item.bazaar_name}
              </div>
            </td>
            {/* <td>Home and kitchen appliances</td> */}
            <td> {item.wholesellers}</td>
            <td>{item.agents}</td>
            <td>{item.states}</td>
            <td>{formatIndianRupee(item.earnings)}</td>
            <td>{item.bills}</td>
            <td className="flex justify-end">
              {/* <img src={LogoDot} alt={"Logo"} style={{ float: "right" }} /> */}

              <GridOptionButton
                icon={"vertical-options"}
                menus={[
                  {
                    label: (
                      <>
                        <a onClick={(e: any) => { navigate(routePaths?.newbazaars, { state: { Bstep: item.bazaar_step, Bid: item.id } }); }} style={{ padding: 0, borderBottom: 0 }}>
                          <span className="icon">
                            <img src={fill} alt="fill" />{" "}
                          </span>{" "}
                          Edit Bazaar
                        </a>
                      </>
                    ),
                  },
                  {
                    label: (
                      <>
                        <a onClick={(e: any) => navigate(`/electronicsbazaar/${item.id}`)} style={{ padding: 0, borderBottom: 0 }}>
                          <span className="icon">
                            <img src={fill} alt="fill" />{" "}
                          </span>{" "}
                          Veiw Details
                        </a>
                      </>
                    ),
                  }
                ]}
              />
            </td>
          </tr>
        ))}
      </table>
      <div className="flex items-center justify-between pagination my-[40px]" style={{ display: "flex", width: '100%' }}>
            <div className="text-[#84818A] text-sm font-medium">Show <span>{totalCount}</span> from {getAllBazar.length} products</div>
            <Pagination count={Math.ceil(totalCount / 10)} page={currentPage} onChange={handlePageChange} />
          </div>
    </div>
  );
};

export { BazaarsListView };
