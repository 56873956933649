import React, { useEffect, useState, useRef } from "react";
import { useAddbazaarStyles } from "@/static/stylesheets/molecules";
import UploaderFrame from "@/static/icons/uploader-frame.png";
import { MenuItem, Select, TextField, Checkbox, Box, IconButton, Grid, FormHelperText, Autocomplete, Button } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import CityPicker from "@/components/atoms/LocationPickers/CityPicker";
import { readFileAsBase64 } from "@/helper/base64";
import CloseIcon from '@mui/icons-material/Close';
import { DistrictPickerNew } from "@/components/atoms/LocationPickers/DistrictPickerNew";
import { CityPickerNew } from "@/components/atoms/LocationPickers/CityPickerNew";
import { StatePicker } from "@/components/atoms/LocationPickers/StatePicker";
import ListItemText from '@mui/material/ListItemText';
import { input } from "@material-tailwind/react";
import './BazaarDetails.css'
import { useLocation, useParams } from "react-router-dom";
import { AlertError } from "@/alert/Alert";
import * as Yup from 'yup'
import { ErrorMessage, Formik } from "formik";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Delete } from "@mui/icons-material";
import Loading from "@/components/LoadingCom/Loading";



const BazaarDetails = (props: {
  formData: any;
  bazaarDetails: any;
  setFormData: (arg0: any) => void;
  actionButtonContent?: any,
  stateCount?: number | null,
  handleNext?: any
}) => {
  const classes = useAddbazaarStyles();

  const [masterType, setMasterType] = React.useState<any[]>([]);
  const [masterType2, setMasterType2] = React.useState<any[]>([]);
  const [masterType3, setMasterType3] = React.useState<any[]>([]);
  const [AllState, setAllState] = React.useState<any[]>([]);
  const [AllDis, setAllDis] = React.useState<any[]>([]);
  const [AllCity, setAllCity] = React.useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChecked, setSelectedCheck] = useState<any>({});
  const location = useLocation();
  const [bazaarNames, setBazaarNames] = useState<any[]>([]);
  const { id } = useParams();
  const [stateWiseData, setStateWiseData] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  const handleChangeMasterType = (event: any, handleChange: any) => {
    const {
      target: { value },
    } = event;
    setMasterType([value]);
    const int = [value];
    const selectedData = typeof value === 'string' ? value.split(',') : value;
    console.log("LOGGER ===> ", AllDis);
    if (props?.formData?.is_locked) {
      props.setFormData((prev: any) => {
        if (location.state?.Bid) {
          if (!props.bazaarDetails.bazaar_state.includes(value)) {
            handleChange('bazaar_state', prev.bazaar_state.includes(value) ? prev.bazaar_state.filter((item: number | string) => item !== value) : prev.bazaar_state.length >= ((props.stateCount || 0) + 1) ? prev.bazaar_state : prev.bazaar_state.concat(value))
            return {
              ...prev,
              bazaar_state: prev.bazaar_state.includes(value) ? prev.bazaar_state.filter((item: number | string) => item !== value) : prev.bazaar_state.length >= ((props.stateCount || 0) + 1) ? prev.bazaar_state : prev.bazaar_state.concat(value),
            }
          } else {
            return {
              ...prev
            }
          }

        } else {
          handleChange('bazaar_state', [value])
          return {
            ...prev,
            bazaar_state: [value],
            bazaar_district: [],
            bazaar_city: []
          }
        }
      });
    } else {
      props.setFormData((prev: any) => {
        if (location.state?.Bid) {
          handleChange('bazaar_state', prev.bazaar_state.includes(value) ? prev.bazaar_state.filter((item: number | string) => item !== value) : prev.bazaar_state.length >= ((props.stateCount || 0) + 1) ? prev.bazaar_state : prev.bazaar_state.concat(value))
          return {
            ...prev,
            bazaar_state: prev.bazaar_state.includes(value) ? prev.bazaar_state.filter((item: number | string) => item !== value) : prev.bazaar_state.length >= ((props.stateCount || 0) + 1) ? prev.bazaar_state : prev.bazaar_state.concat(value),
            bazaar_district: [],
            bazaar_city: []
          }
        } else {
          handleChange('bazaar_state', [value])

          return {
            ...prev,
            bazaar_state: [value],
            bazaar_district: [],
            bazaar_city: []
          }
        }
      });
    }


    setAllCity([])
    // getAllDis({ ids: event.target.value.join(",") });
    // setIsOpen(false);
  };
  console.log(AllState, AllCity, AllDis, "masterType1111")
  console.log(masterType, masterType2, masterType3, "masterType1")

  const handleOptioncheckd = (id: any) => {
    const isChecked = selectedChecked[id];
    setSelectedCheck((prevState: any) => ({
      ...prevState,
      [id]: !isChecked,
    }));

  }


  const handleChangeDistrict = (id: number[], type: { all: boolean }, handleChange: any) => {

    if (props?.formData?.is_locked) {
      let ids = [...masterType2];

      console.log("logger123...", ids, props.bazaarDetails.bazaar_district, id)


      id?.forEach(item => {
        if (type) {
          if (type?.all) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        } else {
          if (!ids.includes(item)) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        }
      })

      ids = [...ids.filter((item: any) => !props.bazaarDetails.bazaar_district?.includes(item)), ...props.bazaarDetails.bazaar_district]

      setMasterType2(ids);

      props.setFormData({
        ...props.formData,
        bazaar_district: ids,
      });
      handleChange("bazaar_district", ids);
      // getAllDis({ ids });
      getAllCity({ ids: ids.join(",") });

    } else {
      let ids = [...masterType2];
      id?.forEach(item => {
        if (type) {
          if (type?.all) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        } else {
          if (!ids.includes(item)) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        }
      })
      setMasterType2(ids);

      props.setFormData({
        ...props.formData,
        bazaar_district: ids,
      });
      handleChange("bazaar_district", ids);
      // getAllDis({ ids });
      getAllCity({ ids: ids.join(",") });
    }

  };

  useEffect(() => {
    setMasterType2(masterType2);
  }, [masterType2])


  console.log(masterType2, "type11")

  const handleChangeCity = (id: number[], type: { all: boolean }, stateIndex: number, disIndex: number) => {

    if (props?.formData?.is_locked) {
      let ids = [...stateWiseData[stateIndex].districts[disIndex].cities];
      id.forEach((item: any) => {
        if (type) {
          if (type?.all) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        } else {
          if (ids.includes(item)) {
            let tempArr = ids;
            const index = tempArr.indexOf(item);
            if (index > -1) { // only splice array when item is found
              tempArr.splice(index, 1); // 2nd parameter means remove one item only
            }
            ids = tempArr;
          } else {
            ids.push(item);
          }
        }
      })

      ids = [...ids.filter((item: any) => !props.bazaarDetails.bazaar_city.includes(item)), ...props.bazaarDetails.bazaar_city]

      // props.setFormData({
      //   ...props.formData,
      //   bazaar_city: ids,
      // });

      let data = stateWiseData;
      data[stateIndex].districts[disIndex].cities = ids;
      setStateWiseData([...data])


      // handleChange("bazaar_city", ids)
    } else {
      let ids = [...stateWiseData[stateIndex].districts[disIndex].cities];
      id.forEach((item: any) => {
        if (type) {
          if (type?.all) {
            ids.push(item);
          } else {
            ids = ids.filter(it => it !== item)
          }
        } else {
          if (ids.includes(item)) {
            let tempArr = ids;
            const index = tempArr.indexOf(item);
            if (index > -1) { // only splice array when item is found
              tempArr.splice(index, 1); // 2nd parameter means remove one item only
            }
            ids = tempArr;
          } else {
            ids.push(item);
          }
        }
      })

      // props.setFormData({
      //   ...props.formData,
      //   bazaar_city: ids,
      // });

      let data = stateWiseData;
      data[stateIndex].districts[disIndex].cities = ids;
      setStateWiseData([...data])

      // handleChange("bazaar_city", ids)
    }

  };

  const handleChangeMasterType2 = (event: any) => {
    setMasterType2(event.target.value);
    // console.log(event.target.value.join(","));
    const str = event.target.value.map(Number);
    props.setFormData({
      ...props.formData,
      bazaar_district: str,
    });
    console.log("str", str);
    getAllCity({ ids: event.target.value });
  };
  const handleChangeMasterType3 = (event: any) => {
    setMasterType3(event.target.value);
    // console.log(event.target.value.join());
    const str = event.target.value.map(Number);
    props.setFormData({
      ...props.formData,
      bazaar_city: str,
    });
  };
  const [file, setFiles] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const imageRef = useRef<HTMLInputElement>(null)

  const [initialValue, setInitialValue] = useState<any>({
    image_url: props.formData.bazaar_image,
    bazaar_name: props.formData.bazaar_name,
    bazaar_state: props.formData.bazaar_state,
    bazaar_district: props.formData.bazaar_district,
    bazaar_city: props.formData.bazaar_city
  })

  const formSchema = Yup.object().shape({
    image_url: Yup.string().required("Please Upload Image"),
    bazaar_name: Yup.string().required("Bazaar name is required").trim(),
    // bazaar_state: Yup.array().min(1, "Bazaar state is required").required("Bazaar state is required"),
    // bazaar_district: Yup.array().min(1, "Bazaar district is required").required("Bazaar district is required"),
    // bazaar_city: Yup.array().min(1, "Bazaar city is required").required("Bazaar city is required")
  })


  const getAllBazaarsNames = async () => {
    try {
      const response = await AppService.getAllBazaars();
      console.log("RESPONSE ====> ", response.data);
      console.log("FORMDATA ===> ", props.formData, response.data)
      if (props.formData.id) {
        let allBazaars = response?.data?.filter((item: any) => item.id !== props.formData.id).map((item: any) => item.bazaar_name);
        setBazaarNames(allBazaars);
      } else {
        if (!(location?.state?.Bid || id)) {
          let allBazaars = response?.data?.map((item: any) => item.bazaar_name);
          setBazaarNames(allBazaars);
        }
      }
    } catch (error) {
      console.log("ERROR ====> ", error)
    }
  }

  useEffect(() => {
    console.log(bazaarNames, props.bazaarDetails.id)
  }, [bazaarNames])

  useEffect(() => {
    if (props.bazaarDetails.id && !initialValue?.id) {
      console.log("logger12345.123", props.bazaarDetails, initialValue)
      setSelectedImage(props.bazaarDetails.bazaar_image);
      setInitialValue(
        {
          ...props.bazaarDetails,
          image_url: props.bazaarDetails.bazaar_image,
          bazaar_name: props.bazaarDetails.bazaar_name,
          bazaar_state: props.bazaarDetails.bazaar_state,
          bazaar_district: props.bazaarDetails.bazaar_district,
          bazaar_city: props.bazaarDetails.bazaar_city
        }
      )

      setStateWiseData([...props.bazaarDetails.state_wise_data])
      getAllBazaarsNames();
    } else {
      getAllBazaarsNames();
    }
  }, [props.bazaarDetails])

  const validateImageDimensions = async (file: File): Promise<boolean> => {
    try {
      // Check if the file is an image
      if (!file.type.startsWith('image/')) {
        AlertError("Please select a valid image file");
        throw new Error('Please select a valid image file.');
      }

      // Use FileReader to read the image file
      const reader = new FileReader();

      await new Promise<void>((resolve, reject) => {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target?.result as string;

          img.onload = () => {
            // Check if the image has the desired dimensions (512px x 512px)
            if (img.width === 512 && img.height === 512) {
              resolve();
            } else {
              AlertError('Please select an image with dimensions 512px x 512px.');
              reject(new Error('Please select an image with dimensions 512px x 512px.'));
            }
          };
        };

        reader.readAsDataURL(file);
      });

      return true; // Validation passed
    } catch (error) {
      // console.error('Validation error:', error.message);
      return false; // Validation failed
    }
  };


  const imageChange = async (e: any, handleChange: any) => {
    console.log("imageChange", e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      const isValid = await validateImageDimensions(e.target.files[0]);
      console.log("Logger123...456", isValid)
      if (!isValid) {
        return;
      }
      console.log(e.target.files[0]);
      // setSelectedImage(e.target.files[0]);
      setFiles(e.target.files[0]);
      setFileName(e.target.files[0].name);
      console.log(e.target.files[0].name);
      let url = await readFileAsBase64(e.target.files[0]);
      handleChange('image_url', url);
      props.setFormData({
        ...props.formData,
        bazaar_image: url,
      });
      setSelectedImage(url);
    }
  };

  // React.useEffect(() => { setSelectedImage(props.formData.bazaar_image) }, [props.formData])

  const getAllLists = async () => {
    const responseJson = await AppService.getTotalStates();
    // console.log(responseJson.data.bazaar);
    setAllState(responseJson.data);
  };

  useEffect(() => {
    let bazaar_state = props.formData.bazaar_state
    const array1: any = []
    AllDis?.length && AllDis?.filter((item: any) => bazaar_state.includes(item.state))?.flatMap((x: any) => {
      x.district?.map((y: any) => {
        console.log("SELECTED VALUES", y)
        if (props.bazaarDetails.bazaar_district?.includes(y.id)) {
          return (array1.push(y.id))
        }
      })
    })
    console.log("SELECTED VALUES ===> ", props.bazaarDetails.bazaar_district, array1, bazaar_state, AllDis)
    props.setFormData({
      ...props.formData,
      bazaar_district: array1
    })
  }, [AllDis])

  useEffect(() => {
    let bazaar_district = props.formData.bazaar_district
    const array1: any = []
    AllCity?.length && AllCity?.filter((item: any) => bazaar_district.includes(item.district))?.flatMap((x: any) => {
      x.city?.map((y: any) => {
        console.log("SELECTED VALUES", y)
        if (props.bazaarDetails.bazaar_city?.includes(y.id)) {
          return (array1.push(y.id))
        }
      })
    })
    console.log("SELECTED VALUES ===> ", props.bazaarDetails.bazaar_city, array1, bazaar_district, AllDis)
    props.setFormData({
      ...props.formData,
      bazaar_city: array1
    })
  }, [AllCity])

  const getAllDis = async (param: any) => {
    if (param.ids !== "") {
      const responseJson = await AppService.getAllDistricByState(param);
      setAllDis(responseJson.data.results);
    } else {
      setAllDis([]);
    }
  };

  const onStateSelect = async (value: any, index: number) => {
    let data = stateWiseData;
    data[index].state = value;
    setStateWiseData([...data]);
    let param = {
      ids: value
    }
    const responseJson = await AppService.getAllDistricByState(param);
    data[index].districtsList = responseJson.data.results;
    setStateWiseData([...data]);
  }

  const onDistrictSelect = async (value: any, stateIndex: number, disIndex: number) => {
    let data = stateWiseData;
    data[stateIndex].districts[disIndex].district = value;
    setStateWiseData([...data]);
    let param = {
      ids: value
    }
    const responseJson = await AppService.getAllCityByDis(param);
    data[stateIndex].districts[disIndex].cityList = responseJson.data.results;
    data[stateIndex].districts[disIndex].cities = [];
    setStateWiseData([...data]);
  }

  const getAllCity = async (param: any) => {
    const responseJson = await AppService.getAllCityByDis(param);
    setAllCity(responseJson.data.results);
  };


  const addAllDistricts = async (index: number) => {
    setLoader(true);
    console.log("LOGGER123.... ====> ",props.bazaarDetails)
    // return;
    try {
      let data = JSON.parse(JSON.stringify(stateWiseData));
      data[index].districts = [];

      for (let i = 0; i < data[index].districtsList[0].district.length; i++) {
        let param = {
          ids: data[index].districtsList[0].district[i].id
        }
        const responseJson = await AppService.getAllCityByDis(param);

        data[index].districts = [
          ...data[index].districts,
          {
            district: data[index].districtsList[0].district[i].id,
            cityList: responseJson.data.results,
            cities: props?.formData?.is_locked ? props?.bazaarDetails?.state_wise_data?.[index]?.districts?.[i]?.cities || [] : []
          }
        ]
      }

      setStateWiseData([...data])
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      console.log("ERROR ====> ", err)
    }

  }

  useEffect(() => {
    getAllLists();

    if (props.formData.bazaar_state && props.formData.bazaar_district) {
      getAllDis({
        ids:
          props.formData.bazaar_state.length > 1
            ? props.formData.bazaar_state.join(",")
            : props.formData.bazaar_state[0],
      });
      setTimeout(() => {
        setMasterType(props.formData.bazaar_state);
        setMasterType2(props.formData.bazaar_district);
        setMasterType3(props.formData.bazaar_city);
      }, 200);
    }
  }, [props.formData.bazaar_state]);

  useEffect(() => {

    if (props.formData.bazaar_district.length) {
      getAllCity({
        ids: props.formData.bazaar_district.join(",")
      });
    }
    setTimeout(() => {
      setMasterType(props.formData.bazaar_state);
      setMasterType2(props.formData.bazaar_district);
      setMasterType3(props.formData.bazaar_city);
    }, 200);

  }, [props.formData.bazaar_district]);

  const removeMedia = (handleChange: any) => {
    setSelectedImage(undefined);
    if (imageRef.current) {
      imageRef.current.value = ""
      handleChange("image_url", "")
    }
    // localStorage.removeItem("img");
  };

  console.log("alldis11", AllCity)
  return (
    <div className={classes.root}>
      {
        loader ?
          <Loading />
          : null }
          <Formik
            initialValues={initialValue}
            onSubmit={(values: any) => {
              if (bazaarNames.filter((item: any) => item?.trim()?.toLowerCase() === values.bazaar_name?.trim()?.toLowerCase())?.length) {
                if (bazaarNames?.length) {
                  AlertError("This Bazaar name already exists")
                }
                return
              }
              let data: any = {
                bazaar_state: [],
                bazaar_district: [],
                bazaar_city: []
              }

              if (!stateWiseData.length) {
                AlertError("State is required");
                return;
              }

              let error = false;

              console.log("logger123...",stateWiseData)
              stateWiseData.map((stateObj: any) => {
                // Map states
                if (stateObj.state) {
                  data.bazaar_state.push(stateObj.state);
                } else {
                  console.log("State is required");
                  error = true
                }

                if (!stateObj.districts.length) {
                  console.log("District is required");
                  error = true
                }
                // Map districts
                stateObj.districts.map((districtObj: any) => {
                  if (districtObj.district) {
                    data.bazaar_district.push(districtObj.district);
                  } else {
                    console.log('District not available for State: ' + AllState.find((item:any)=>stateObj.states === item.id).state);
                    error = true;
                  }

                  if (!districtObj.cities.length) {
                    console.log("City is required");
                    error = true
                  }

                  districtObj.cities.map((city: any) => {
                    if (city !== null && city !== undefined) {
                      data.bazaar_city.push(city as string);
                    } else {
                      console.log('City not available for District: ' + (districtObj.district || 'Unknown District'));
                      error = true;
                    }
                  });


                });
              });

              if (error) {
                AlertError("Please fill required * fields");
                return;
              }

              // return

              // values.stateWiseData = stateWiseData;
              values.bazaar_state = data.bazaar_state
              values.bazaar_district = data.bazaar_district
              values.bazaar_city = data.bazaar_city
              values.bazaar_image = values.image_url
              console.log("logger123...123", stateWiseData, data, values)
              // return;
              props.handleNext(values)
            }}
            validationSchema={formSchema}
            enableReinitialize={true}
          >
            {
              ({
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                touched,
                errors,
                handleSubmit
              }) => (
                <Grid container>
                  <div className="w-full">
                    <Grid item lg={5}>
                      <div className="pt-[50px]">
                        <div className={classes.content}>
                          <div className="py-5 h-[300px] flex flex-col justify-center">
                            <div className="uploadIcon">
                              {selectedImage === undefined ? (
                                null
                              ) : (
                                selectedImage && (
                                  <>
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        zIndex: "2",
                                        background: "#ffffff",
                                        top: "15px",
                                        right: "10px",
                                      }}
                                      onClick={() => removeMedia(setFieldValue)}
                                      size={"small"}
                                      className={"shadow-md"}
                                    >
                                      <CloseIcon style={{ fontSize: "17px" }} />
                                    </IconButton>

                                    <Box
                                      className={"absolute top-0 bottom-0 left-0 right-0 bg-red-400 z-[1] rounded-[5.30337px]"}
                                      sx={{

                                      }}
                                    >
                                      <img
                                        src={selectedImage}
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          height: '100%'
                                        }}
                                      />
                                    </Box>
                                  </>
                                )
                              )}
                            </div>
                            <input
                              onChange={(e) => imageChange(e, setFieldValue)}
                              ref={imageRef}
                              className={"hidden"}
                              type="file"
                              name="image_url"
                              accept="image/png"
                              id="formFile"
                            />
                            <label htmlFor="formFile">
                              <div className="flex justify-center">
                                <img src={UploaderFrame} style={{ width: '50px' }} alt={"Uploader"} />
                              </div>
                              <div className={"title"}>Upload Media here <span className="text-red-600">*</span></div>
                              <div className="py-1"></div>
                              <div className={"subtitle"}>
                                Image can be size of 512 PX by 512 PX Only
                                <br />
                                Accepts .png only
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="text-red-600 text-xs mt-2">
                          <ErrorMessage name="image_url" />
                        </div>
                      </div>
                    </Grid>
                  </div>

                  <Grid item width={'680px'}>
                    <div className="py-[30px]">
                      <div>
                        <p className="fieldTitle">Bazaar Name <span className="text-red-600">*</span></p>
                        <TextField
                          sx={{
                            "& *": {
                              fontFamily: "Manrope !important",
                              fontSize: "14px",
                            },
                          }}
                          disabled={props?.formData?.is_locked}
                          variant="standard"
                          value={props.formData.bazaar_name}
                          onChange={(e) => {

                            setFieldValue("bazaar_name", e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                            props.setFormData({
                              ...props.formData,
                              bazaar_name: e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''),
                            })

                          }
                          }
                          fullWidth={true}
                        />
                        {
                          props?.formData?.is_locked &&
                          <FormHelperText>This field is not editable as this bazaar has been assigned to some wholeseller or agent</FormHelperText>
                        }
                        <div className="text-red-600 text-xs mt-2">
                          <ErrorMessage name="bazaar_name" />
                          {
                            (values.bazaar_name && bazaarNames?.length && bazaarNames.filter((item: any) => item?.trim().toLowerCase() === values.bazaar_name?.trim()?.toLowerCase())?.length) ?
                              <p className="mb-0">This Bazaar name already exists</p> : ""
                          }
                        </div>
                      </div>
                      <div className="flex justify-between gap-4 py-[20px]">
                        <p>Select States <span className="text-red-600">*</span></p>
                        <Button variant="contained" className="nextButton" onClick={() => {
                          setStateWiseData([
                            ...stateWiseData,
                            {
                              states: null,
                              districtsList: [],
                              districts: []
                            }
                          ])
                        }}>
                          Add State
                        </Button>
                      </div>

                      {
                        stateWiseData.map((item: any, index: number) => (
                          <div className="grid grid-cols-2 pb-[30px]">
                            <div className={""}>
                              <p className="fieldTitle">Select State <span className="text-red-600">*</span></p>

                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={AllState.map((item: any) => ({ label: item.state, value: item.id, ...item }))}
                                sx={{ width: 300 }}
                                disabled={props?.formData?.is_locked && props.bazaarDetails.bazaar_state.includes(item.state)}
                                value={AllState.find((state) => state.id === item.state)?.state || null}
                                onChange={(e, newValue) => {
                                  console.log("logger123...", newValue)
                                  if(!stateWiseData.map((item:any)=>item.state).includes(newValue.id)){
                                    onStateSelect(newValue.id, index);
                                  } else {
                                    AlertError("Duplicate states not allowed!")
                                  }
                                  // handleChangeMasterType({ target: { value: newValue.id } }, setFieldValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" fullWidth />
                                )}
                              />
                              {/* {
                            props?.formData?.is_locked &&
                            <FormHelperText>This field is not editable as this bazaar has been assigned to some wholeseller or agent</FormHelperText>
                          } */}
                              {/* <div className="text-red-600 text-xs mt-2">
                            <ErrorMessage name="bazaar_state" />
                          </div> */}
                            </div>
                            <div>

                              {
                                (props?.formData?.is_locked &&  props?.bazaarDetails?.bazaar_state?.includes(item.state)) ? null :
                                  <IconButton onClick={() => {
                                    let data = stateWiseData;
                                    data.splice(index, 1)
                                    setStateWiseData([...data])
                                  }}>
                                    <Delete style={{ color: 'red' }} />
                                  </IconButton>
                              }

                            </div>
                            {
                              item.state ?
                                <div className="flex col-span-2 justify-between gap-4 py-[10px]">
                                  <p>Select Districts <span className="text-red-600">*</span></p>
                                  <div className="flex gap-2">
                                    <Button variant="contained" className="nextButton" onClick={() => {
                                      let data = stateWiseData;
                                      data[index].districts = [
                                        ...data[index].districts,
                                        {
                                          district: null,
                                          cityList: [],
                                          cities: []
                                        }
                                      ]
                                      setStateWiseData([...data])
                                    }}>
                                      Add District
                                    </Button>

                                    <Button variant="contained" className="nextButton" onClick={() => addAllDistricts(index)}>
                                      Add All District
                                    </Button>
                                  </div>
                                </div>
                                : null
                            }

                            {
                              item.districts.map((district: any, disIndex: number) => (
                                <div className=" col-span-2 ml-4 flex py-1">
                                  <div className={"w-[50%]"}>
                                    <p className="fieldTitle">Select District <span className="text-red-600">*</span></p>
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      options={item.districtsList[0].district.map((item: any) => ({ label: item.district, ...item }))}
                                      sx={{ width: 300 }}
                                      disabled={props?.formData?.is_locked && props.bazaarDetails.bazaar_district.includes(district.district)}
                                      value={item.districtsList[0].district.find((ele: any) => ele.id === district.district)?.district || null}
                                      onChange={(e, newValue) => {
                                        console.log("logger123...", newValue, item)

                                        
                                        
                                        if(!stateWiseData[index].districts.map((item:any)=>item.district).includes(newValue.id)){
                                          onDistrictSelect(newValue.id, index, disIndex);
                                        } else {
                                          AlertError("Duplicate district not allowed!")
                                        }

                                        // handleChangeMasterType({ target: { value: newValue.id } }, setFieldValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} variant="standard" fullWidth />
                                      )}
                                    />
                                  </div>

                                  <div className={"w-[40%]"}>
                                    {/* <p className="fieldTitle">Select Cities <span className="text-red-600">*</span></p> */}
                                    {/* <Autocomplete
                                  disablePortal
                                  multiple
                                  id="combo-box-demo"
                                  options={item.districtsList[0].district[disIndex]?.cityList?.[0]?.city?.map((ele: any) => ({ label: ele.city, ...ele })) || []}
                                  sx={{ width: 300 }}

                                  value={item.districtsList[0].district[disIndex]?.cityList?.[0]?.city?.find((ele: any) => district[disIndex].cities.includes(ele.id))?.city || []}
                                  onChange={(e, newValue) => {
                                    console.log("logger123...", newValue)
                                    // let data = stateWiseData;
                                    // data[index].state = newValue.id;
                                    // setStateWiseData(data)
                                    // handleChangeMasterType({ target: { value: newValue.id } }, setFieldValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="standard" fullWidth />
                                  )}
                                /> */}


                                    <CityPickerNew
                                      label={"Select Cities "}
                                      required
                                      placeholder={"Select Cities"}
                                      hint={"Search"}
                                      variant={"outlined"}
                                      states={item.districtsList[0].district.map((item: any) => ({ label: item.district, ...item }))}
                                      selectedStates={[district.district]}
                                      selectedcity={district.cities}
                                      selectedDistricts={[district.district]}
                                      districts={district.cityList}
                                      selectedCity={district.cities}
                                      onSelectItem={(id: any, type: { all: boolean }) => {
                                        console.log("City Log....", id, type)
                                        // !props?.formData?.is_locked &&
                                        handleChangeCity(id, type, index, disIndex)
                                      }
                                      } />
                                    {/* {
                    props?.formData?.is_locked &&
                    <FormHelperText>This field is not editable as this bazaar has been assigned to some wholeseller or agent</FormHelperText>
                  }
                  <div className="text-red-600 text-xs mt-2">
                    <ErrorMessage name="bazaar_city" />
                  </div> */}

                                  </div>
                                  {
                                    (props?.formData?.is_locked &&  props?.bazaarDetails?.bazaar_district?.includes(district.district)) ? null :
                                      <IconButton onClick={() => {
                                        let data = stateWiseData;
                                        data[index].districts.splice(disIndex, 1)
                                        setStateWiseData([...data])
                                      }}>
                                        <Delete style={{ color: 'red' }} />
                                      </IconButton>
                                  }
                                </div>
                              ))
                            }
                            {/* {masterType.length && AllDis.length ? (
                      <div>
              <p className="fieldTitle">District</p>
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                multiple={true}
                value={masterType2}
                onChange={handleChangeMasterType2}
              >
                {AllDis.map((items: any) => {
                  return items?.district.map((item: any) => {
                    return <MenuItem value={item.id}>{item.district}</MenuItem>;
                  });
                })}
              </Select>
            </div>
          ) : null} */}
                          </div>
                        ))
                      }
                      {/*{masterType.length && masterType2.length ? (
                    <div>
                    <p className="fieldTitle">City</p>
                    <Select
                    label="Age"
                    variant={"standard"}
                    fullWidth={true}
                    multiple={true}
                    value={masterType3}
            onChange={handleChangeMasterType3}
            >
            {AllCity.map((items: any) => {
              return items?.city.map((item: any) => {
                  return <MenuItem value={item.id}>{item.city}</MenuItem>;
                });
              })}
            </Select>
            </div>
          ) : null}*/}

                      {/* <CityPicker
              label={"Select Cities"}
              placeholder={"All Cities"}
              hint={"Search"}
              variant={"outlined"}
              states={AllDis}
              selectedStates={masterType2}
              districts={AllCity}
              selectedDistricts={masterType3}
              onSelectItem={(id: any) => handleChangeCity(id)}
            /> */}
                      {/* {props?.formData?.bazaar_district?.join(",")} */}



                      {props?.actionButtonContent(handleSubmit)}
                    </div>
                  </Grid>
                </Grid>
              )
            }
          </Formik>
      
    </div>
    // <div className="mt-[56px]">
    //   <div className="w-[467px] h-[169px] relative rounded-[8px] border-[1.5px] cursor-pointer flex justify-center flex-col border-dashed border-[#E1E1E1]">
    //     <input type="file" className="w-full h-full absolute top-0 left-0 opacity-0" />
    //     {
    //       selectedImage === undefined ?
    //         <div className="text-center">
    //           <div className="flex justify-center">
    //             <img src={UploaderFrame} alt={"Uploader"} className="w-[50px]" />
    //           </div>
    //           <div className="font-[Manrope] text-[17px] text-[#4E2FA9] font-[500] mb-[6px] mt-[3px]">Upload Media here</div>
    //           <div className="mt-[5px] font-[Manrope] text-[12px] text-[#84818A] font-[500]">Image can be size of 512 PX by 512 PX Only</div>
    //         </div>
    //         : <img
    //           src={selectedImage}
    //           style={{
    //             width: "100%",
    //             borderRadius: "5px",
    //             height: '100%'
    //           }} />
    //     }
    //  </div>
    //  </div>
  );
};

export default BazaarDetails;
